import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "../components/Header/Header";
import HomePage from "./home";
import PagesPage from "./pages";
import AddPage from "./pages/AddPage/AddPage";
import EditPage from "./pages/EditPage/EditPage";
import Login from "./login/Login";
import { authenticateUser, loginUser } from "../functions/postFunctions";
import axios from "axios";
import GamesPage from "./games";
import AddGame from "./games/addGame/AddGame";
import TeamsPage from "./teams";
import EditGame from "./games/editGame/EditGame";
import PlayersPage from "./players";
import AddPlayer from "./players/addPlayer/AddPlayer";
import EditPlayer from "./players/editPlayer/EditPlayer";
import CompetitionsPage from "./competitions";
import TablesPage from "./tables";
import StaffPage from "./staff";
import AddStaff from "./staff/addStaff/AddStaff";
import EditStaff from "./staff/editStaff/EditStaff";
import VideosPage from "./video/VideosPage";
import PlayerCategoryPage from "./players/playerCategory/PlayerCategory";
import PageCategoryPage from "./pages/PageCategory/PageCategory";
import MenusPage from "./menus/MenusPage";
import MenusItems from "./menus/MenusItems/MenusItems";
import ProductsCategory from "./products/productsCategory/ProductsCategory";
import ProductsPage from "./products";
import AddProduct from "./products/AddProduct/AddProduct";
import EditProduct from "./products/EditProduct/EditProduct";
import OrdersPage from "./orders";
import OrderDetails from "./orders/OrderDetails/OrderDetails";

class App extends React.Component {
  state = {
    breadcrumbs: "Flex CMS",
    loggedIn: false,
    token: "",
    errorMessage: ""
  };

  setBreadcrumbs = breadcrumbs => {
    this.setState({ breadcrumbs });
  };

  componentDidMount() {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          localStorage.clear();
          this.setState({ token: "", loggedIn: false });
          window.location = "/";
        }
        return error;
      }
    );
    if (localStorage.getItem("Authorization")) {
      this.setState({
        loggedIn: true,
        token: localStorage.getItem("Authorization")
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    authenticateUser(localStorage.getItem("Authorization")).then(res => {});
  }

  handleLogin = (username, password) => {
    loginUser(username, password).then(res => {
      if (res.success) {
        localStorage.setItem("Authorization", `Bearer ${res.access_token}`);
        this.setState({
          token: `Bearer ${res.access_token}`,
          loggedIn: true
        });
        window.location = "/";
      } else {
        this.setState({
          errorMessage:
            "Korisničko ime ili lozinka nijesu tačni. Molimo unesite ispravne podatke."
        });
      }
    });
  };

  render() {
    const { loggedIn, token, errorMessage } = this.state;
    if (loggedIn) {
      return (
        <BrowserRouter basename="/">
          <Navbar />
          <Route
            path="/"
            render={props => (
              <Header {...props} breadcrumbs={this.state.breadcrumbs} />
            )}
          />
          <main className="px-40 pt-20 pb-40">
            <div className="content">
              {/*<Route*/}
              {/*  exact*/}
              {/*  path="/"*/}
              {/*  render={props => (*/}
              {/*    <HomePage {...props} breadcrumbs={this.state.breadcrumbs} />*/}
              {/*  )}*/}
              {/*/>*/}
              <Route
                exact
                path="/(meniji|menus)"
                render={props => (
                  <MenusPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    token={token}
                    setBreadcrumbs={this.setBreadcrumbs}
                  />
                )}
              />
              <Route
                exact
                path="/(meniji|menus)/:id"
                render={props => (
                  <MenusItems
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    token={token}
                    setBreadcrumbs={this.setBreadcrumbs}
                  />
                )}
              />
              <Route
                exact
                path="/"
                render={props => (
                  <PagesPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    token={token}
                    setBreadcrumbs={this.setBreadcrumbs}
                  />
                )}
              />
              <Route
                exact
                path="/(kategorije-stranice|category-page)"
                render={props => (
                  <PageCategoryPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    token={token}
                    setBreadcrumbs={this.setBreadcrumbs}
                  />
                )}
              />
              <Route
                exact
                path="/(dodaj-stranicu|add-page)"
                render={props => (
                  <AddPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(izmjeni-stranicu|edit-page)/:id"
                render={props => (
                  <EditPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(proizvodi|products)"
                render={props => (
                  <ProductsPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(dodaj-proizvod|add-product)"
                render={props => (
                  <AddProduct
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(izmjeni-proizvod|edit-product)/:id"
                render={props => (
                  <EditProduct
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(kategorije-proizvodi|product-categories)"
                render={props => (
                  <ProductsCategory
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(porudzbine|orders)"
                render={props => (
                  <OrdersPage
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
              <Route
                exact
                path="/(porudzbine|orders)/:id"
                render={props => (
                  <OrderDetails
                    {...props}
                    breadcrumbs={this.state.breadcrumbs}
                    setBreadcrumbs={this.setBreadcrumbs}
                    token={token}
                  />
                )}
              />
            </div>
          </main>
        </BrowserRouter>
      );
    } else {
      return (
        <BrowserRouter basename="/">
          <Route
            path="/"
            render={props => (
              <Login
                {...props}
                breadcrumbs={this.state.breadcrumbs}
                handleLogin={this.handleLogin}
                errorMessage={errorMessage}
              />
            )}
          />
        </BrowserRouter>
      );
    }
  }
}

export default App;
