import React, { Component } from "react";
import {
  getAllPages,
  deletePage,
  getAllCategoriesPage
} from "../../functions/postFunctions";
import { LinkButton, CustomButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import { dateLocalisation } from "../../functions/functions";
import SearchableDropdown from "../../components/Form/Dropdown/SearchableDropdown";

class PagesPage extends Component {
  state = {
    theads: ["Cover", "Naslov", "Link", "Stranica ažurirana", "Akcije"],
    pages: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Stranice");
    getAllCategoriesPage().then(res => {
      if (res.success) {
        const categoryList = res.categories.map(e => ({
          value: e.id,
          label: e.name
        }));
        const currentCategory = categoryList.find(e => e.value === 1);
        this.setState({ categoryList, currentCategory });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
  }

  handleDeletePage = id => {
    const { pages } = this.state;
    const newPages = pages.filter(e => e.id !== id);
    deletePage(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ pages: newPages });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllPages(1, 20, 0, currentCategory.value).then(res => {
      if (res.success) {
        this.setState({
          pages: res.pages,
          page: 1,
          removeLoadMore: res.pages.length < 20
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllPages(1, 20, page * 20, currentCategory.value).then(res => {
      if (res.success) {
        this.setState(({ pages, page }) => ({
          pages: [...pages, ...res.pages],
          page: page + 1,
          removeLoadMore: res.pages.length < 20
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { theads, pages, categoryList, currentCategory } = this.state;
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdown
                data={categoryList}
                placeholder="Kategorija"
                name="currentCategory"
                handleChange={this.handleInput}
                value={currentCategory}
              />
            </div>
            <div className="col-md-9">
              <LinkButton href="dodaj-stranicu">Dodaj stranicu</LinkButton>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {pages.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <img
                    src={
                      this.state.currentCategory.value === 1
                        ? "/images/cover/mf.jpg"
                        : e.cover
                    }
                    alt={e.slug}
                    style={{ maxHeight: "50px" }}
                  />
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.title}</span>
                </td>
                <td className="td px-10">
                  <a
                    href={`https://www.fk-buducnost.me/${
                      this.state.currentCategory.value === 2 ? "clanak/" : ""
                    }${e.slug}`}
                    className="f-s-16 transition-1 py-5 br-r-5 text-color-primary regular-link"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Pogledaj stranicu
                  </a>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">
                    {dateLocalisation(e.updated_at)}
                  </span>
                </td>
                <td className="td px-10" style={{ minWidth: "220px" }}>
                  <LinkButton
                    href={`izmjeni-stranicu/${e.slug}`}
                    className="mr-20"
                  >
                    Izmjeni
                  </LinkButton>
                  <CustomButton
                    onClick={() => this.handleDeletePage(e.id)}
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className="wrapper mt-40 d-flex justify-content-center">
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default PagesPage;
