import React from "react";

const Logo = props => {
  return (
      <img
          src="images/logo.png"
          alt=""/>
  );
};

export default Logo;
